
import { defineComponent } from "vue";
import HeaderSection from "../HeaderSection.vue";
import { QuestionType } from "@toppick/common/build/interfaces";
import "../../styles.css";

interface PickerItem {
  title: string;
  image: string;
  query: string;
  type: QuestionType;
}
export const pickerItems: PickerItem[] = [
  {
    image: require("@/assets/images/search.svg"),
    title: "All",
    query: "all",
    type: QuestionType.Default,
  },
  {
    image: require("@/assets/images/party-hat.svg"),
    title: "Fun",
    query: "fun",
    type: QuestionType.Fun,
  },
  {
    image: require("@/assets/images/speed.svg"),
    title: "Speed Friending",
    query: "speed-friending",
    type: QuestionType.SpeedFriending,
  },
  {
    image: require("@/assets/images/discussion.svg"),
    title: "Discussion",
    query: "discussion",
    type: QuestionType.Discussion,
  },
  {
    image: require("@/assets/images/knowledge_icon.svg"),
    title: "Knowledge",
    query: "knowledge",
    type: QuestionType.Knowledge,
  },
];

export default defineComponent({
  emits: ["onDelete", "onShowAuthModal"],
  components: { HeaderSection },
  data() {
    return {
      currentItem: pickerItems[0] as PickerItem,
      pickerItems,
    };
  },
  methods: {
    onClickPickerItem(index: number) {
      this.currentItem = pickerItems[index];
    },
    goToPicker() {
      this.$router.push({
        name: "QuestionPickerPlay",
        query: { type: this.currentItem.query },
      });
    },
  },

  computed: {
    currentPickerDescription(): string {
      switch (this.currentItem.type) {
        case QuestionType.Fun:
          return "Sometimes talking about something that’s amusing and inconsequential can be exactly what you need to have the best conversation possible. This is especially the case when the person you’re talking to doesn’t really seem interested in discussing more serious issues; or is too tired after a long day of work to give elaborate, well-thought-out answers to your questions.";

        case QuestionType.SpeedFriending:
          return "Using these questions will help you start a good-natured conversation with a complete stranger and put you on the right path to becoming friends with them in no time. All of the questions in this category are likely to inspire the person you're talking with to share many of their feelings and personal experiences.";

        case QuestionType.Discussion:
          return "Sharing your opinions with others and, perhaps more importantly, listening to theirs is a great way to keep a conversation going. Just remember that you should be respectful of their opinions, even if they are polar opposites of yours. So pick this category if you're looking for a variety of questions involving some of the most thought-provoking topics of discussion.";

        case QuestionType.Knowledge:
          return "Our library contains thousands of questions that can help you have easier conversations with others, no matter how close you are with them or how well you know each other. As you will see, a majority of our questions have sample answers, articles, and videos, giving you a more in-depth idea as to what you can expect when asking these questions.";

        default:
          return "Our library contains thousands of questions that can help you have easier conversations with others, no matter how close you are with them or how well you know each other. As you will see, a majority of our questions have sample answers, articles, and videos, giving you a more in-depth idea as to what you can expect when asking these questions.";
      }
    },
  },
});
