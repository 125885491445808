<template>
  <section class="space about_hero cmn_hero_banner inner_cmn_hero text-center">
    <div class="container">
      <h1>Question Picker</h1>
    </div>
  </section>
</template>

<script>
export default {};
</script>
